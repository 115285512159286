<template>
	<div>
		<h2 class="content-block">Survey Management</h2>

		<dx-data-grid ref="datagridComponent" style="width:100%;" class="dx-card wide-card"
			:data-source="dataSourceConfig"
			:focused-row-index="0"
			:show-borders="false"
			:focused-row-enabled="true" :focusedRowIndex="-1"
			:column-auto-width="false"
			:column-hiding-enabled="true"
			:allowColumnResizing="true" columnResizingMode="widget"
			@exporting="onExporting"
		>
			<dx-state-storing :enabled="false" type="localStorage" storage-key="manage-surveys" />
			<dx-column-chooser :enabled="true" mode="select" :searchEnabled="true" height="400px">
				<dx-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />
			</dx-column-chooser>
			<dx-paging :page-size="defaultPagerSize" />
			<dx-pager :show-page-size-selector="true" :show-info="true" :allowed-page-sizes="[5, 10, 15, 20, 50]" />
			<dx-filter-row :visible="true" />
			<dx-header-filter :visible="true" />
			<dx-search-panel :visible="true" width="300px" />
			<dx-toolbar>
				<dx-item location="before" name="groupBySwitcher" v-if="false">
					<dx-button icon="hierarchy" hint="Group by school"
						styling-mode="contained" :type="groupBySchool ? 'success' : 'default'"
						@click="groupBySwitcher"
					/>
				</dx-item>
				<dx-item location="before" name="groupPanel" />
				<dx-item location="before" name="searchPanel" />
				<dx-item location="after" name="refreshButton">
					<dx-button icon="refresh" hint="Refresh data" @click="refreshButton" />
				</dx-item>
				<dx-item location="after" name="exportButton" />
				<dx-item location="after" name="columnChooserButton" />
			</dx-toolbar>
			<dx-scrolling :useNative="true" />
			<dx-export :enabled="true" :formats="['xlsx', 'pdf']" />
			<dx-editing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="form" />
			<dx-grouping :context-menu-enabled="false" expand-mode="rowClick" :autoExpandAll="false" />
			<dx-group-panel :visible="true" :allow-column-dragging="false" />

			<dx-column data-field="id" data-type="number" caption="SvyID" :width="90" :allow-header-filtering="false" />
			<dx-column data-field="updated_at" data-type="datetime" caption="Last changed" sortOrder="desc" :width="160" :allow-header-filtering="false" />
			<dx-column data-field="current_job_status" data-type="string" caption="Has Job?" :width="100" :allow-header-filtering="false" />
			<dx-column caption="Current Employer">
				<dx-column data-field="employer_site" data-type="string" caption="Site" :width="120" :allow-header-filtering="true" />
				<dx-column data-field="employer_region" data-type="string" caption="Region" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="employer_country" data-type="string" caption="Country" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="employer_kind" data-type="string" caption="Kind" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="employer_kind_specifics" data-type="string" caption="Kind Specifics" :width="140" :allow-header-filtering="false" />
			</dx-column>
			<dx-column caption="Current Job">
				<dx-column data-field="job_position" data-type="string" caption="Position" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="job_position_area" data-type="string" caption="Description" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="job_duration_ends" data-type="date" caption="Ends At" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="job_intent" data-type="string" caption="Intent after" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="job_intent_same_place" data-type="string" caption="Stay same place? " :width="150" :allow-header-filtering="false" />
			</dx-column>
			<dx-column caption="Job Applications">
				<dx-column data-field="employment_plans" data-type="string" caption="Plans" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="employment_applied_to_regions" data-type="string" caption="Regions applied" :width="170" :allow-header-filtering="false" />
				<dx-column data-field="employment_applied_to_countries" data-type="string" caption="Countries applied" :width="170" :allow-header-filtering="false" />
				<dx-column data-field="employment_no_job_reason" data-type="string" caption="Reason for not applying" :width="170" :allow-header-filtering="false" />
			</dx-column>
			<dx-column caption="Preferred Work Environment">
				<dx-column data-field="job_prefer_employer_kind" data-type="string" caption="Kind" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="job_prefer_employer_site" data-type="string" caption="Site" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="job_prefer_employer_region" data-type="string" caption="Region" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="job_prefer_employer_country" data-type="string" caption="Country" :width="140" :allow-header-filtering="false" />
				<dx-column data-field="job_prefer_employer_position" data-type="string" caption="Position" :width="140" :allow-header-filtering="true" />
				<dx-column data-field="job_prefer_employer_position_desc" data-type="string" caption="Description" :width="140" :allow-header-filtering="false" />
			</dx-column>
			<dx-column data-field="allow_profile_matching" data-type="string" caption="Profile Matching" :width="140" :allow-header-filtering="false" />
			<dx-column data-field="created_at" data-type="datetime" caption="Submitted at" :width="160" :allow-header-filtering="false" />
		</dx-data-grid>
	</div>
</template>

<script>
import CustomStore from 'devextreme/data/custom_store';
import DxDataGrid, { DxColumn, DxFilterRow, DxPager, DxPaging, DxColumnChooser, DxPosition, DxStateStoring, DxToolbar, DxItem, DxSearchPanel,
	DxScrolling, DxHeaderFilter, DxExport, DxEditing, DxGrouping, DxGroupPanel
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { inject, ref } from "vue";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import devices from "devextreme/core/devices"

export default {
	setup() {
		const datagridComponent = ref(null);			// Template ref for our main datagrid.
		const $http = inject('$http');
		const dataSourceConfig = new CustomStore({
			key: 'id',
			loadMode: 'raw',
			remoteOperations: false,
			load: () => {
				return $http.get('/survey').then(response => {
					for (var rIdx in response.data) {
						response.data[rIdx].course = 'NLE';
					}
					return response.data;
				});
			}
		});
		const onExporting = (e) => {
			let dateCode = (new Date()).toISOString().substring(0, 10);
			if (e.format == 'xlsx') {
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('Main sheet');
				exportDataGrid({
					component: e.component,
					worksheet: worksheet,
					customizeCell: function(options) {
						options.excelCell.font = { name: 'Arial', size: 12 };
						options.excelCell.alignment = { horizontal: 'left' };
					} 
				}).then(function() {
					workbook.xlsx.writeBuffer()
						.then(function(buffer) {
							saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Surveys-' + dateCode + '.xlsx');
						});
				});
			} else if (e.format == 'pdf') {
				const doc = new jsPDF();
				exportDataGridToPdf({
					jsPDFDocument: doc,
					component: e.component
				}).then(() => {
					doc.save('Surveys-' + dateCode + '.pdf');
				});
			}
		};

		// Allow toggle button for automatically switching to group-by school column - don't allow other grouping selections.
		const groupBySchool = ref(false);
		const groupBySwitcher = () => {
			groupBySchool.value = !groupBySchool.value;
		}

		// Use 10 rows by default for phones instead of 15. 
		const defaultPagerSize = devices.current().deviceType == 'phone' ? 10 : 15;

		// Regex to detect valid facebook ID's where we can show a link.
		const facebookIdRegex = /^[a-z0-9\\.]+$/i;

		// Refresh datagrid via internal DevExtreme methods.
		const refreshButton = () => {
			datagridComponent.value.instance.refresh();
		}

		return { dataSourceConfig, onExporting, groupBySwitcher, groupBySchool, defaultPagerSize, facebookIdRegex, refreshButton, datagridComponent };
	},
	components: {
		DxDataGrid, DxColumn, DxFilterRow, DxPager, DxPaging, DxColumnChooser, DxPosition, DxStateStoring, DxToolbar, DxItem, DxButton,
		DxSearchPanel, DxScrolling, DxHeaderFilter, DxExport, DxEditing, DxGrouping, DxGroupPanel
	}
};
</script>

<style>
.fieldName {
	display: inline-block;
	width: 100px;
	margin: 0.5em 0;
	font-weight: bold;
}
</style>