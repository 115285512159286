<template>
	<div class="dashboard-home">
		<h2 class="content-block">Dashboard</h2>
		<div class="content-block">
			<DxLoadPanel v-model:visible="isLoading" />
			<div class="dx-card dashboard-card">
				<div class="header">At a glance</div>
				<DxBox v-if="userStatus.display == 'staff'" :element-attr="{ class: 'staffHint' }">
					<DxItem :ratio="1">
						<span>You are logged in as a staff member.</span>
					</DxItem>
				</DxBox>
				<DxBox :element-attr="{ class: applicationStatus ? 'success' : '' }" :direction="isTiny ? 'col' : 'row'">
					<DxItem :ratio="0" :base-size="isTiny ? 'auto' : 150">Applications</DxItem>
					<DxItem :ratio="isTiny ? 0 : 1" :base-size="isTiny ? 'auto' : 0">
						<div>
							<div v-if="applicationStatus">
								<i class="dx-icon dx-icon-check"></i>
								<span>Your application {{ applicationStatus.id }} was filed on {{ applicationStatus.updated_at }}.</span>
							</div>
							<div v-else>
								<i class="dx-icon dx-icon-warning"></i>
								<span><RouterLink to="/application">Application not filed yet.</RouterLink></span>
							</div>
						</div>
					</DxItem>
				</DxBox>
				<DxBox :element-attr="{ class: surveyStatus ? 'success' : '' }" :direction="isTiny ? 'col' : 'row'">
					<DxItem :ratio="0" :base-size="isTiny ? 'auto' : 150">Surveys</DxItem>
					<DxItem :ratio="isTiny ? 0 : 1" :base-size="isTiny ? 'auto' : 0">
						<div>
							<div v-if="surveyStatus">
								<i class="dx-icon dx-icon-check"></i>
								<span>Your survey {{ surveyStatus.id }} was filed on {{ surveyStatus.updated_at }}.</span>
							</div>
							<div v-else>
								<i class="dx-icon dx-icon-warning"></i>
								<span><RouterLink to="/survey">Survey not filed yet.</RouterLink></span>
							</div>
						</div>
					</DxItem>
				</DxBox>
			</div>
		</div>
	</div>
</template>

<script setup>
import auth from "../auth";
import { useRouter } from 'vue-router';
import { onMounted, inject, ref, computed } from 'vue'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import notify from 'devextreme/ui/notify';
const $http = inject('$http');
const isLoading = ref(true);
const userStatus = ref({});
const router = useRouter();
import { sizes } from "../utils/media-query";

const isTiny = sizes()["screen-x-small"];

const applicationStatus = computed(() => {
	if (!userStatus.value || !Array.isArray(userStatus.value.applications) || userStatus.value.applications.length <= 0)
		return false;
	return {
		id: 'APP' + (userStatus.value.applications[0].id + "").padStart(6, "0"),
		updated_at: (new Date(userStatus.value.applications[0].updated_at)).toLocaleString('en-HK'),
	};
});
const surveyStatus = computed(() => {
	if (!userStatus.value || !Array.isArray(userStatus.value.surveys) || userStatus.value.surveys.length <= 0)
		return false;
	return {
		id: 'SRV' + (userStatus.value.surveys[0].id + "").padStart(6, "0"),
		updated_at: (new Date(userStatus.value.surveys[0].updated_at)).toLocaleString('en-HK'),
	};
});

// Fetch application/survey status for current user to check if we should auto-redirect.
const fetchUserStatus = $http.get('/user/me');

onMounted(() => {
	fetchUserStatus.then((response) => {
		isLoading.value = false;
		userStatus.value = response.data;
	}).catch(err => {
		isLoading.value = false;
		if (err.response.status == 401) {
			auth.logOut();
			router.push({
				path: "/login-form",
				query: { redirect: "/home" }
			});
			return;
		}
		console.error([err]);
		notify({ message: "Failed to communicate with server!", position: 'center', shading: true, shadingColor: '#cccc' }, "error", 3000);
		userStatus.value = {};
	});
});
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.dashboard-card {
	margin: 1em 0;
	padding: 20px;

	.header {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 1em;
	}

	.dx-box {
		margin-bottom: 1em;
		border-left: 15px solid $base-accent;
		border-radius: 10px;
		background: linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(221,221,221,0) calc(max(50%, 550px)));
		.dx-icon {
			vertical-align: top;
			line-height: 17px;
			padding-right: 5px;
			color: $base-accent;
		}
		.dx-item {
			padding: 20px 10px;
		}
		&.success {
			border-left-color: green;
			.dx-icon {
				color: green;
			}
		}
		&.staffHint {
			border-left-color: $base-text-color;
		}
	}
}
.app.screen-x-small .dashboard-card .dx-box .dx-item {
	padding: 10px 10px;
}
</style>