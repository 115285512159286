import appInfo from "./app-info";
import xior from 'xior';
import { inject, computed } from "vue";

let AuthSystem = {};

// The authentication section here uses a manual xior instance instead of the provide/inject version because
// we don't have access to setup() or anything else here at the top level where we could use inject from Vue.
const $httpRaw = xior.create({
	baseURL: (appInfo.baseURL.endsWith('/') ? appInfo.baseURL.substr(0, appInfo.baseURL.length - 1) : appInfo.baseURL),
	headers: { 'Accept': 'application/json' },
});

// Default user for testing and debugging.
const defaultUser = {
	email: 'sandra@example.com',
	avatarUrl: appInfo.storageURL + '/test_employee.png'
};
const overrideLogin = false;

// Support for remembering password and session - the first time Auth is checked (usually for loggedIn() checks),
// see if we have access to localStorage stored auth keys.
let startupUser = null;
let startupToken = null;
if (localStorage) {
	try {
		startupUser = localStorage.getItem(appInfo.localPrefix + 'User');
		startupUser = JSON.parse(startupUser);
		startupToken = localStorage.getItem(appInfo.localPrefix + 'Auth');
	} catch (ex) {
		startupUser = null;
		startupToken = null;
		console.error("Exception loading remembered user. Clearing login session.");
		localStorage.removeItem(appInfo.localPrefix + 'User');
		localStorage.removeItem(appInfo.localPrefix + 'Auth');
	}
}
// Start an immediate refresh check on our token if logged in already - just to make sure everything is OK.
// TODO: REFRESH API CALL!

AuthSystem = {
	// Note that _user & _token are not reactive!
	_user: overrideLogin ? defaultUser : (startupUser ? startupUser : null),
	_token: startupToken ? startupToken : null,

	loggedIn() {
		const $httpSystem = inject('$http');
		if (this._user && this._token) {
			$httpSystem.config.headers['Authorization'] = 'Bearer ' + this._token;
		} else {
			$httpSystem.config.headers['Authorization'] = '';
		}
		return !!this._user;
	},

	isStaff: computed(() => {
		return !!AuthSystem._user && AuthSystem._user.id && AuthSystem._user.id == 1;
	}),

	// Attempt login with given email/password. Returns { isOk, message, data }.
	async logIn(email, password) {
		try {
			await $httpRaw.get('/../sanctum/csrf-cookie');
			let csrfCookie = RegExp('XSRF-TOKEN' + "=[^;]+").exec(document.cookie);
			csrfCookie = decodeURIComponent(csrfCookie ? csrfCookie.toString().replace(/^[^=]+./, "") : "");
			let tokenRequest = await $httpRaw.post('/user/login', { 'email': email, 'password': password }, {
				headers: { 'X-XSRF-TOKEN': csrfCookie }
			});
			let userProfile = tokenRequest.data.user;
			userProfile['avatarUrl'] = defaultUser['avatarUrl'];
			this._user = userProfile;
			this._token = tokenRequest.data.token;
			localStorage.setItem(appInfo.localPrefix + 'User', JSON.stringify(userProfile));
			localStorage.setItem(appInfo.localPrefix + 'Auth', this._token);

			return {
				isOk: true,
				data: this._user,
			}
		}
		catch {
			return {
				isOk: false,
				message: "Authentication failed - check your password"
			};
		}
	},

	async logOut() {
		this._user = null;
		this._token = null;
		localStorage.removeItem(appInfo.localPrefix + 'User');
		localStorage.removeItem(appInfo.localPrefix + 'Auth');
	},

	// Fetch public metadata on a user login identified by email (i.e. does it exist, potential 2FA, etc.)
	async userLoginMetadata(email) {
		try {
			await $httpRaw.get('/../sanctum/csrf-cookie');
			let csrfCookie = RegExp('XSRF-TOKEN' + "=[^;]+").exec(document.cookie);
			csrfCookie = decodeURIComponent(csrfCookie ? csrfCookie.toString().replace(/^[^=]+./, "") : "");
			let metaResult = await $httpRaw.post('/user/metalogin', { 'email': email }, {
				headers: { 'X-XSRF-TOKEN': csrfCookie }
			});
			return {
				isOk: true,
				data: metaResult.data,
			}
		} catch {
			return { isOk: false, message: "Email lookup failed" };
		}
	},

	async getUser() {
		try {
			// Send request
			console.log("Getting user...");
			return {
				isOk: true,
				data: this._user,
			};
		}
		catch {
			return {
				isOk: false
			};
		}
	},

	async resetPassword(email) {
		try {
			// Send request
			console.log(email);

			return {
				isOk: true
			};
		}
		catch {
			return {
				isOk: false,
				message: "Failed to reset password"
			};
		}
	},

	async changePassword(email, recoveryCode) {
		try {
			// Send request
			console.log(email, recoveryCode);

			return {
				isOk: true
			};
		}
		catch {
			return {
				isOk: false,
				message: "Failed to change password"
			}
		}
	},

	async createAccount(email, password) {
		try {
			await $httpRaw.get('/../sanctum/csrf-cookie');
			let csrfCookie = RegExp('XSRF-TOKEN' + "=[^;]+").exec(document.cookie);
			csrfCookie = decodeURIComponent(csrfCookie ? csrfCookie.toString().replace(/^[^=]+./, "") : "");
			let tokenRequest = await $httpRaw.post('/user/register', { 'email': email, 'password': password }, {
				headers: { 'X-XSRF-TOKEN': csrfCookie }
			});
			
			let userProfile = tokenRequest.data.user;
			userProfile['avatarUrl'] = defaultUser['avatarUrl'];
			this._user = userProfile;
			this._token = tokenRequest.data.token;
			localStorage.setItem(appInfo.localPrefix + 'User', JSON.stringify(userProfile));
			localStorage.setItem(appInfo.localPrefix + 'Auth', this._token);

			return {
				isOk: true,
				data: this._user,
			}
		}
		catch {
			return {
				isOk: false,
				message: "Failed to create account"
			};
		}
	}
};

console.log("Checking isstaff...");
console.log(AuthSystem.isStaff.value);

export default AuthSystem;