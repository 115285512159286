export default [
    { code: 'ILOILO', title: 'ILOILO', region: 'Iloilo' },
    { code: 'BACOLOD', title: 'BACOLOD', region: 'Negros Occidental' },
    { code: 'PALAWAN', title: 'PALAWAN', region: 'Palawan' },
    { code: 'AKLAN', title: 'AKLAN', region: 'Aklan' },
    { code: 'ROXAS', title: 'ROXAS', region: 'Capiz' },
    { code: 'ANTIQUE', title: 'ANTIQUE', region: 'Antique' },
    { code: 'TUGUEGARAO', title: 'TUGUEGARAO', region: 'Cagayan' },
    { code: 'DIPOLOG', title: 'DIPOLOG', region: 'Zamboanga del Norte' },
    { code: 'MANILA', title: 'MANILA', region: 'Metro Manila' },
    { code: 'DAVAO', title: 'DAVAO', region: 'Davao' },
    { code: 'GENSAN', title: 'GENSAN', region: 'Cagayan' },
    { code: 'NUEVAVIZCAYA', title: 'NUEVA VIZCAYA', region: 'Nueva Vizcaya' },
];