<template>
	<div>
		<h2 class="content-block">Profile</h2>

		<div class="content-block dx-card responsive-paddings">
			Profile controls - coming soon!
		</div>

		<div class="content-block dx-card responsive-paddings" style="opacity:0.2;" v-if="auth.isStaff.value">
			<div class="form-avatar">
				<img :src="imageSrc" />
			</div>
			<span>{{ formData.Notes }}</span>
		</div>

		<div class="content-block dx-card responsive-paddings" style="opacity:0.2;" v-if="auth.isStaff.value">
			<dx-form
				id="form"
				label-location="top"
				:form-data="formData"
				:colCountByScreen="colCountByScreen"
			/>
		</div>
	</div>
</template>

<script>
import auth from "../auth";
import DxForm from "devextreme-vue/form";

export default {
	props: {
		picture: String
	},
	setup() {
		const picture = "images/employees/06.png";
		
		// const imageSrc = `https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${picture}`;
		const imageSrc = `https://pinky.cirii.org/storage/test_employee.png`;
		const formData = {
			ID: 7,
			FirstName: "Sandra",
			LastName: "Johnson",
			Prefix: "Mrs.",
			Position: "Controller",
			Picture: picture,
			BirthDate: new Date("1974/11/5"),
			HireDate: new Date("2005/05/11"),
			Notes:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ligula enim, porttitor facilisis nisi quis, gravida sollicitudin tortor. Integer ipsum velit, volutpat vitae eros et, luctus varius ex. In molestie, elit ut feugiat gravida, felis nisi viverra sapien, in vestibulum velit sem sit amet dolor. Nulla fringilla lectus non nisi pharetra interdum. Proin at fringilla nisl. Nullam non est id neque facilisis sagittis. Morbi odio urna, ornare non ante ac, ullamcorper fringilla tellus. Donec dapibus laoreet ex, at pretium diam. Sed dictum ex eu nunc congue, euismod egestas mi elementum. Aenean id libero blandit, pretium velit a, rhoncus augue. Pellentesque iaculis gravida leo, ac interdum ligula egestas et. Nulla a urna eget enim sodales hendrerit non non odio. Integer nec arcu tincidunt, vulputate ante vitae, commodo augue. Cras lorem neque, consequat nec hendrerit ut, eleifend ut libero. Proin malesuada eu risus a blandit.",
			Address: "4600 N Virginia Rd."
		};
		const colCountByScreen = {
			xs: 1,
			sm: 2,
			md: 3,
			lg: 4
		}

		return {
			imageSrc,
			formData,
			colCountByScreen,
			auth
		};
	},
	components: {
		DxForm
	}
};
</script>

<style lang="scss">
.form-avatar {
	float: left;
	height: 120px;
	width: 120px;
	margin-right: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #fff;
	overflow: hidden;

	img {
		height: 120px;
		display: block;
		margin: 0 auto;
	}
}
</style>
