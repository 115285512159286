export default [
    {
        "location": "Private University, Dagupan City, Pangasinan",
        "title": "Lyceum Northwestern University",
        "slug": "lyceum-northwestern-university",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Institutional University, Dasmarinas City, Cavite",
        "title": "De La Salle Medical and Health Sciences Institute",
        "slug": "de-la-salle-health-sciences-institute",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Pasay, Metro Manila",
        "title": "Arellano University in Pasay",
        "slug": "arellano-university-in-pasay",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Emilio Aguinaldo College",
        "slug": "emilio-aguinaldo-college",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Iloilo City, Iloilo",
        "title": "Central Philippine University",
        "slug": "central-philippine-university",
        "region": "Iloilo",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State University, Cebu City, Cebu",
        "title": "Cebu Normal University",
        "slug": "cebu-normal-university",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Iloilo City, Iloilo",
        "title": "West Visayas State University",
        "slug": "west-visayas-state-university",
        "region": "Iloilo",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cebu City, Cebu",
        "title": "University of Southern Philippines Foundation",
        "slug": "university-of-southern-philippines-foundation",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cebu City, Cebu",
        "title": "Southwestern University",
        "slug": "southwestern-university",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Angeles City, Pampanga",
        "title": "Angeles University Foundation",
        "slug": "angeles-university-foundation",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Baliuag, Bulacan",
        "title": "Baliuag University",
        "slug": "baliuag-university",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Dagupan City, Pangasinan",
        "title": "University of Luzon",
        "slug": "university-of-luzon",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Dumaguete City, Negros Oriental",
        "title": "Negros Oriental State University",
        "slug": "negros-oriental-state-university",
        "region": "Negros Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Baybay City, Leyte",
        "title": "Visayas State University",
        "slug": "visayas-state-university",
        "region": "Leyte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Baguio City, Benguet",
        "title": "University of the Cordilleras",
        "slug": "university-of-the-cordilleras",
        "region": "Benguet",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Silang, Cavite",
        "title": "Adventist University of the Philippines",
        "slug": "adventist-university-of-the-philippines",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Lipa City, Batangas",
        "title": "Lipa City Colleges",
        "slug": "lipa-city-colleges",
        "region": "Batangas",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "University of Cebu - Banilad Campus",
        "slug": "university-of-cebu-banilad-campus",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Bacolod City, Negros Occidental",
        "title": "STI West Negros University",
        "slug": "west-negros-college",
        "region": "Negros Occidental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Lucena City, Quezon",
        "title": "Sacred Heart College",
        "slug": "sacred-heart-college",
        "region": "Quezon",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Quezon City, Metro Manila",
        "title": "University of the East Ramon Magsaysay",
        "slug": "university-of-the-east-ramon-magsaysay",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Las Pinas, Metro Manila",
        "title": "University of Perpetual Help System DALTA in Las Piñas",
        "slug": "university-of-perpetual-help-dalta",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "St. Paul University Manila",
        "slug": "st-paul-university-manila",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Municipal University, Marikina City, Metro Manila",
        "title": "Pamantasan ng Lungsod ng Marikina",
        "slug": "pamantasan-ng-lungsod-ng-marikina",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "La Consolacion College - Manila",
        "slug": "la-consolacion-college-manila",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Davao Doctors College",
        "slug": "davao-doctors-college",
        "region": "Davao",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State College, Sagay City, Negros Occidental",
        "title": "Northern Negros State College of Science and Technology",
        "slug": "northern-negros-state-college-of-science-and-technology",
        "region": "Negros Occidental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Juan City, Metro Manila",
        "title": "Dominican College",
        "slug": "dominican-college",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Mandaluyong, Metro Manila",
        "title": "Our Lady of Guadalupe Colleges",
        "slug": "our-lady-of-guadalupe-colleges",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Davao City, Davao",
        "title": "University of the Immaculate Conception",
        "slug": "university-of-the-immaculate-conception",
        "region": "Davao",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "Wesleyan University Philippines",
        "slug": "wesleyan-university",
        "region": "Nueva Ecija",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private School, Manila, Metro Manila",
        "title": "Adamson University",
        "slug": "adamson-university",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tagum City, Davao del Norte",
        "title": "St. Mary's College of Tagum",
        "slug": "st-marys-college-of-tagum-inc",
        "region": "Davao del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Iloilo City, Iloilo",
        "title": "Saint Paul University Iloilo",
        "slug": "saint-paul-university-iloilo",
        "region": "Iloilo",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Pasay, Metro Manila",
        "title": "San Juan De Dios Educational Foundation",
        "slug": "san-juan-de-dios-educational-foundation",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Baguio City, Benguet",
        "title": "Baguio Central University",
        "slug": "baguio-central-university",
        "region": "Benguet",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Bacolod City, Negros Occidental",
        "title": "University of St. La Salle",
        "slug": "university-of-saint-la-salle",
        "region": "Negros Occidental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cagayan de Oro, Misamis Oriental",
        "title": "Liceo de Cagayan University",
        "slug": "liceo-de-cagayan-university",
        "region": "Misamis Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Quezon City, Metro Manila",
        "title": "Trinity University of Asia",
        "slug": "trinity-university-of-asia",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Quezon City, Metro Manila",
        "title": "Our Lady of Fatima University - Quezon City Campus",
        "slug": "our-lady-of-fatima-university-in-quezon-city",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Batangas City, Batangas",
        "title": "Lyceum of the Philippines University - Batangas Campus",
        "slug": "lyceum-of-the-philippines-university-batangas",
        "region": "Batangas",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Binan City, Laguna",
        "title": "St. Michael's College of Laguna",
        "slug": "saint-michael-s-college-of-laguna",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "Southeast Asian College",
        "slug": "southeast-asian-college-inc",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Municipal University, Manila, Metro Manila",
        "title": "Pamantasan ng Lungsod ng Maynila",
        "slug": "pamantasan-ng-lungsod-ng-maynila",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Philippine College of Health Sciences",
        "slug": "philippine-college-of-health-sciences-inc",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "Philippine Women's University",
        "slug": "philippine-women-s-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Lucena City, Quezon",
        "title": "Calayan Educational Foundation",
        "slug": "calayan-educational-foundation-inc",
        "region": "Quezon",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Butuan City, Agusan del Norte",
        "title": "Father Saturnino Urios University",
        "slug": "father-saturnino-urios-university",
        "region": "Agusan del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Echague, Isabela",
        "title": "Isabela State University",
        "slug": "isabela-state-university",
        "region": "Isabela",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Baguio City, Benguet",
        "title": "University of Baguio",
        "slug": "university-of-baguio",
        "region": "Benguet",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Cebu City, Cebu",
        "title": "University of the Visayas",
        "slug": "university-of-the-visayas",
        "region": "Cebu",
        "courses": [
            "NLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Laoag City, Ilocos Norte",
        "title": "Northwestern University",
        "slug": "northwestern-university",
        "region": "Ilocos Norte",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, La Union",
        "title": "Union Christian College",
        "slug": "union-christian-college",
        "region": "La Union",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Indang, Cavite",
        "title": "Cavite State University - Indang Campus",
        "slug": "cavite-state-university-indang",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Iligan City, Lanao del Norte",
        "title": "St. Michael's College of Iligan City",
        "slug": "st-michael-s-college-iligan-city",
        "region": "Lanao del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Bacolod City, Negros Occidental",
        "title": "Riverside College",
        "slug": "riverside-college",
        "region": "Negros Occidental",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "Centro Escolar University",
        "slug": "centro-escolar-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Calamba City, Laguna",
        "title": "University of Perpetual Help System DALTA in Calamba",
        "slug": "university-of-perpetual-help-system-dalta-in-calamba",
        "region": "Laguna",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Quezon City, Metro Manila",
        "title": "Far Eastern University Nicanor Reyes Medical Foundation",
        "slug": "feu-nicanor-reyes-medical-foundation",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Davao City, Davao",
        "title": "Ateneo de Davao University",
        "slug": "ateneo-de-davao-university",
        "region": "Davao",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Zamboanga City, Zamboanga del Sur",
        "title": "Western Mindanao State University",
        "slug": "western-mindanao-state-university",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tacloban, Leyte",
        "title": "Holy Infant College",
        "slug": "holy-infant-collegetacloban-city",
        "region": "Leyte",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Baguio City, Benguet",
        "title": "St. Louis University",
        "slug": "saint-louis-university",
        "region": "Benguet",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Cagayan de Oro, Misamis Oriental",
        "title": "Xavier University",
        "slug": "xavier-university",
        "region": "Misamis Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Zamboanga City, Zamboanga del Sur",
        "title": "Ateneo de Zamboanga University",
        "slug": "ateneo-de-zamboanga-university",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Maramag, Bukidnon",
        "title": "Central Mindanao University",
        "slug": "central-mindanao-university",
        "region": "Bukidnon",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Chinese General Hospital Colleges",
        "slug": "chinese-general-hospital-college-of-nursing-and-liberal-arts",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Malolos City, Bulacan",
        "title": "Centro Escolar University - Malolos",
        "slug": "centro-escolar-university-malolos",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Iloilo City, Iloilo",
        "title": "University of San Agustin",
        "slug": "university-of-san-agustin",
        "region": "Iloilo",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State University, La Trinidad, Benguet",
        "title": "Benguet State University",
        "slug": "benguet-state-university",
        "region": "Benguet",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Catholic Private University, Cebu City, Cebu",
        "title": "University of San Carlos",
        "slug": "university-of-san-carlos",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Davao Medical School Foundation",
        "slug": "davao-medical-school-foundation",
        "region": "Davao",
        "courses": [
            "NLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Medical University, Mandaue City, Cebu",
        "title": "Cebu Doctors' University",
        "slug": "cebu-doctors-university",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "State University, Bayambang, Pangasinan",
        "title": "Pangasinan State University - Bayambang Campus",
        "slug": "pangasinan-state-university-in",
        "region": "Pangasinan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Catholic Private University, Dumaguete City, Negros Oriental",
        "title": "St. Paul University Dumaguete",
        "slug": "st-paul-university-dumaguete",
        "region": "Negros Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Baguio City, Benguet",
        "title": "Pines City Colleges",
        "slug": "pines-city-colleges",
        "region": "Benguet",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Cavite City, Cavite",
        "title": "St. Joseph College - Cavite City",
        "slug": "st-joseph-college-cavite-city",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Lucban, Quezon",
        "title": "Southern Luzon State University",
        "slug": "southern-luzon-state-university",
        "region": "Quezon",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Quezon City, Metro Manila",
        "title": "New Era University",
        "slug": "new-era-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Makati, Metro Manila",
        "title": "Makati Medical Center College",
        "slug": "remedios-t-romualdez-memorial-schools-makati-medical-center",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Batangas City, Batangas",
        "title": "Batangas State University",
        "slug": "batangas-state-university",
        "region": "Batangas",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "St. Paul University Quezon City",
        "slug": "st-paul-university-quezon-city",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Iligan City, Lanao del Norte",
        "title": "Mindanao State University - Iligan Institute of Technology",
        "slug": "mindanao-state-university-iligan-institute-of-technology",
        "region": "Lanao del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Naga City, Camarines Sur",
        "title": "Ateneo de Naga University",
        "slug": "ateneo-de-naga-university",
        "region": "Camarines Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Bacoor, Cavite",
        "title": "St. Dominic College of Asia",
        "slug": "st-dominic-college-of-arts-and-sciences",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Iloilo City, Iloilo",
        "title": "Iloilo Doctor's College",
        "slug": "iloilo-doctor-s-college-molo-iloilo-city",
        "region": "Iloilo",
        "courses": [
            "NLE",
            "DLE"
        ]
    },
    {
        "location": "Municipal University, Pasay, Metro Manila",
        "title": "Pamantasan ng Lungsod ng Pasay",
        "slug": "pamantasan-ng-lungsod-ng-pasay",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Bacoor, Cavite",
        "title": "University of Perpetual Help System DALTA in Molino",
        "slug": "university-of-perpetual-help-system-dalta-in-molino",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Davao City, Davao",
        "title": "University of Mindanao",
        "slug": "university-of-mindanao",
        "region": "Davao",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Dasmarinas City, Cavite",
        "title": "Emilio Aguinaldo College - Cavite Campus",
        "slug": "emilio-aguinaldo-college-in-dasmarinas",
        "region": "Cavite",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Butuan City, Agusan del Norte",
        "title": "Butuan Doctors College",
        "slug": "butuan-doctors-college",
        "region": "Agusan del Norte",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Iloilo City, Iloilo",
        "title": "University of Iloilo",
        "slug": "university-of-iloilo",
        "region": "Iloilo",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tacloban, Leyte",
        "title": "Asian Development Foundation College",
        "slug": "asian-development-foundation-college",
        "region": "Leyte",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Santa Cruz, Laguna",
        "title": "Laguna State Polytechnic University",
        "slug": "laguna-state-polytechnic-university",
        "region": "Laguna",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Iligan City, Lanao del Norte",
        "title": "Adventist Medical Center College",
        "slug": "mindanao-sanitarium-and-hospital-college",
        "region": "Lanao del Norte",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "World Citi Colleges - Quezon City Campus",
        "slug": "world-citi-colleges-quezon-city-campus",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Makati, Metro Manila",
        "title": "Centro Escolar University - Makati Gil Puyat",
        "slug": "centro-escolar-university-makati",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Midsayap, Cotabato",
        "title": "Notre Dame Of Midsayap College",
        "slug": "notre-dame-of-midsayap-college",
        "region": "Cotabato",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Mindanao Medical Foundation College",
        "slug": "mindanao-medical-foundation-college",
        "region": "Davao",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "San Pedro College",
        "slug": "san-pedro-college",
        "region": "Davao",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Caloocan City, Metro Manila",
        "title": "Manila Central University",
        "slug": "manila-central-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Perpetual Help College of Manila",
        "slug": "perpetual-help-college-of-manila",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cavite City, Cavite",
        "title": "Saint Jude College",
        "slug": "saint-jude-college",
        "region": "Cavite",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Manila, Metro Manila",
        "title": "University of the Philippines Manila",
        "slug": "university-of-the-philippines-manila",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Legazpi City, Albay",
        "title": "University of Santo Tomas - Legazpi",
        "slug": "aquinas-university",
        "region": "Albay",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State University, Batac City, Ilocos Norte",
        "title": "Mariano Marcos State University",
        "slug": "mariano-marcos-state-university",
        "region": "Ilocos Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, San Carlos City, Pangasinan",
        "title": "Virgen Milagrosa University Foundation",
        "slug": "virgen-milagrosa-university-foundation",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Dagupan City, Pangasinan",
        "title": "University of Pangasinan PHINMA Education Network",
        "slug": "university-of-pangasinan",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Dumaguete City, Negros Oriental",
        "title": "Silliman University",
        "slug": "silliman-university",
        "region": "Negros Oriental",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State University, Malolos City, Bulacan",
        "title": "Bulacan State University - Main Campus",
        "slug": "bulacan-state-university",
        "region": "Bulacan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Brokenshire College",
        "slug": "brokenshire-college",
        "region": "Davao",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Angeles City, Pampanga",
        "title": "Holy Angel University",
        "slug": "holy-angel-university",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE"
        ]
    },
    {
        "location": "Private College, Iligan City, Lanao del Norte",
        "title": "Iligan Medical Center College",
        "slug": "iligan-medical-center-college",
        "region": "Lanao del Norte",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Valencia City, Bukidnon",
        "title": "Mountain View College",
        "slug": "mountain-view-college",
        "region": "Bukidnon",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Cagayan de Oro, Misamis Oriental",
        "title": "Capitol University",
        "slug": "capitol-university",
        "region": "Misamis Oriental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Ozamiz City, Misamis Occidental",
        "title": "Misamis University",
        "slug": "misamis-university",
        "region": "Misamis Occidental",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "Far Eastern University",
        "slug": "far-eastern-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Ozamiz City, Misamis Occidental",
        "title": "La Salle University Ozamiz",
        "slug": "la-salle-university-ozamiz",
        "region": "Misamis Occidental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Municipal University, Manila, Metro Manila",
        "title": "Universidad de Manila",
        "slug": "gat-andres-bonifacio-university",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cauayan City, Isabela",
        "title": "University of Perpetual Help System JONELTA in Cauayan City",
        "slug": "university-of-perpetual-help-system-jonelta-in-cauayan",
        "region": "Isabela",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Bangued, Abra",
        "title": "Divine Word College of Bangued",
        "slug": "divine-word-college-of-bangued",
        "region": "Abra",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Aparri, Cagayan",
        "title": "Lyceum of Aparri",
        "slug": "lyceum-of-aparri",
        "region": "Cagayan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Malaybalay City, Bukidnon",
        "title": "Bukidnon State University",
        "slug": "bukidnon-state-university",
        "region": "Bukidnon",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tarlac City, Tarlac",
        "title": "Central Luzon Doctors' Hospital Educational Institution",
        "slug": "central-luzon-doctors-hospital-educational-institution",
        "region": "Tarlac",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Zamboanga City, Zamboanga del Sur",
        "title": "Pilar College",
        "slug": "pilar-college",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Roxas City, Capiz",
        "title": "St. Anthony College - Roxas",
        "slug": "st-anthony-college-of-nursing-inc",
        "region": "Capiz",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Vigan City, Ilocos Sur",
        "title": "University of Northern Philippines Vigan",
        "slug": "university-of-northern-philippines",
        "region": "Ilocos Sur",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Cagayan de Oro, Misamis Oriental",
        "title": "Cagayan de Oro College-PHINMA Education Network",
        "slug": "cagayan-de-oro-college",
        "region": "Misamis Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Tuguegarao City, Cagayan",
        "title": "University of Saint Louis",
        "slug": "university-of-saint-louis",
        "region": "Cagayan",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Naga City, Camarines Sur",
        "title": "University of Nueva Caceres",
        "slug": "university-of-nueva-caceres",
        "region": "Camarines Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Pablo City, Laguna",
        "title": "San Pablo Colleges",
        "slug": "san-pablo-colleges",
        "region": "Laguna",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Tubigon, Bohol",
        "title": "Mater Dei College",
        "slug": "mater-dei-college",
        "region": "Bohol",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Jose, Occidental Mindoro",
        "title": "Divine Word College of San Jose",
        "slug": "divine-word-college-of-san-jose",
        "region": "Occidental Mindoro",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, General Santos City, South Cotabato",
        "title": "Notre Dame of Dadiangas University",
        "slug": "notre-dame-of-dadiangas-university",
        "region": "South Cotabato",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Ilagan, Isabela",
        "title": "St. Ferdinand College",
        "slug": "saint-ferdinand-college-ilagan-city",
        "region": "Isabela",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, General Santos City, South Cotabato",
        "title": "General Santos Doctors' Medical School Foundation Inc.",
        "slug": "gen-santos-doctors-medical-school-foundation-inc",
        "region": "South Cotabato",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Pablo City, Laguna",
        "title": "Canossa College",
        "slug": "canossa-college",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Municipal University, Cabuyao, Laguna",
        "title": "Pamantasan ng Cabuyao",
        "slug": "pamantasan-ng-cabuyao",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "La Concordia College",
        "slug": "la-concordia-college",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "Philippine Christian University",
        "slug": "philippine-christian-university",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Bocaue, Bulacan",
        "title": "College of Mt. Carmel",
        "slug": "college-of-mt-carmel",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State College, Nabua, Camarines Sur",
        "title": "Camarines Sur Polytechnic Colleges",
        "slug": "camarines-sur-polytechnic-colleges-nabua-main-campus",
        "region": "Camarines Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "De Ocampo Memorial College",
        "slug": "de-ocampo-memorial-college",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Caloocan City, Metro Manila",
        "title": "Saint Dominic Savio College",
        "slug": "saint-dominic-savio-college",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Paranaque City, Metro Manila",
        "title": "Universal Colleges of Paranaque",
        "slug": "universal-colleges-of-paranaque",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Calbayog City, Samar",
        "title": "Christ the King College",
        "slug": "christ-the-king-college",
        "region": "Samar",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Iba, Zambales",
        "title": "Ramon Magsaysay Technological University - Iba",
        "slug": "ramon-magsaysay-technological-university-iba",
        "region": "Zambales",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Balanga City, Bataan",
        "title": "Asia Pacific College of Advanced Studies",
        "slug": "asia-pacific-college-of-advanced-studies",
        "region": "Bataan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Taytay, Rizal",
        "title": "University of Rizal System - Taytay Campus",
        "slug": "university-of-rizal-system-taytay-campus",
        "region": "Rizal",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Batangas City, Batangas",
        "title": "Golden Gate College",
        "slug": "golden-gate-colleges",
        "region": "Batangas",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Antipolo City, Rizal",
        "title": "Unciano Colleges",
        "slug": "unciano-colleges",
        "region": "Rizal",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Angeles City, Pampanga",
        "title": "Systems Plus College Foundation",
        "slug": "systems-plus-college-foundation",
        "region": "Pampanga",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Talisay City, Cebu",
        "title": "Cebu Sacred Heart College",
        "slug": "cebu-sacred-heart-college",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Mandaue City, Cebu",
        "title": "Larmen De Guia Memorial College",
        "slug": "larmen-de-guia-memorial-college",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Tecaro College Foundation",
        "slug": "tecaro-college-foundation",
        "region": "Davao",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Olongapo City, Zambales",
        "title": "Central Luzon College of Science & Technology",
        "slug": "central-luzon-college-of-science-technology",
        "region": "Zambales",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "Dr. Gloria D. Lacson Foundation Colleges Inc. Cabanatuan",
        "slug": "dr-gloria-d-lacson-foundation-colleges-inc-cabanatuan",
        "region": "Nueva Ecija",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Dipolog City, Zamboanga del Norte",
        "title": "Andres Bonifacio College",
        "slug": "andres-bonifacio-college",
        "region": "Zamboanga del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Tuguegarao City, Cagayan",
        "title": "University of Cagayan Valley",
        "slug": "university-of-cagayan-valley",
        "region": "Cagayan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State College, Kalibo, Aklan",
        "title": "Aklan Catholic College",
        "slug": "aklan-catholic-college",
        "region": "Aklan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Surigao City, Surigao del Norte",
        "title": "Saint Paul University Surigao",
        "slug": "saint-paul-university-surigao",
        "region": "Surigao del Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cabanatuan City, Nueva Ecija",
        "title": "Araullo University",
        "slug": "araullo-university",
        "region": "Nueva Ecija",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Leonardo, Nueva Ecija",
        "title": "Dr. Gloria D. Lacson Foundation Colleges",
        "slug": "dr-gloria-d-lacson-foundation-colleges",
        "region": "Nueva Ecija",
        "courses": [
            "NLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Leonardo, Nueva Ecija",
        "title": "L.F. Gonzales College of Science and Technology",
        "slug": "lf-gonzales-college-of-science-and-technology",
        "region": "Nueva Ecija",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Danao City, Cebu",
        "title": "Colegio de San Antonio de Padua",
        "slug": "colegio-de-san-antonio-de-padua",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Malasiqui, Pangasinan",
        "title": "Perpetual Help College of Pangasinan",
        "slug": "university-of-perpetual-help-system-jonelta-in-malasiqui",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Baliuag, Bulacan",
        "title": "St. Mary's College of Baliuag",
        "slug": "saint-marys-college-of-baliuag",
        "region": "Bulacan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "Good Samaritan Colleges",
        "slug": "good-samaritan-colleges",
        "region": "Nueva Ecija",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Candelaria, Quezon",
        "title": "Manuel S. Enverga University Foundation - Candelaria",
        "slug": "manuel-s-enverga-university-foundation-candelaria",
        "region": "Quezon",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Los Banos, Laguna",
        "title": "Trace College",
        "slug": "trace-college",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Tacurong City, Sultan Kudarat",
        "title": "Sultan Kudarat State University",
        "slug": "sultan-kudarat-state-university",
        "region": "Sultan Kudarat",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "St. Bernadette of Lourdes College",
        "slug": "st-bernadette-of-lourdes-college",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Jolo, Sulu",
        "title": "Sulu State College",
        "slug": "sulu-state-college",
        "region": "Sulu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Digos City, Davao del Sur",
        "title": "Cor Jesu College",
        "slug": "cor-jesu-college",
        "region": "Davao del Sur",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Roxas City, Capiz",
        "title": "College of St. John Roxas",
        "slug": "college-of-st-john-roxas",
        "region": "Capiz",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Municipal University, Pasig City, Metro Manila",
        "title": "Pamantasan ng Lungsod ng Pasig",
        "slug": "pamantasan-ng-lungsod-ng-pasig",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Nasugbu, Batangas",
        "title": "Batangas State University - Nasugbu Campus",
        "slug": "batangas-state-university-nasugbu",
        "region": "Batangas",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Jose del Monte City, Bulacan",
        "title": "First City Providential College",
        "slug": "first-city-providential-college",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Calamba City, Laguna",
        "title": "The Lyceum of the Philippines - Laguna",
        "slug": "the-lyceum-of-the-philippines-laguna",
        "region": "Laguna",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Batangas City, Batangas",
        "title": "University of Batangas",
        "slug": "university-of-batangas",
        "region": "Batangas",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Mary Johnston College of Nursing",
        "slug": "mary-johnston-college-of-nursing",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tagaytay City, Cavite",
        "title": "Olivarez College Tagaytay",
        "slug": "olivarez-college-tagaytay",
        "region": "Cavite",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Las Pinas, Metro Manila",
        "title": "Saint Francis of Assisi College - Las Piñas City",
        "slug": "saint-francis-of-assisi-college-las-pinas-city",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Puerto Princesa City, Palawan",
        "title": "Holy Trinity University",
        "slug": "holy-trinity-university",
        "region": "Palawan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Baguio City, Benguet",
        "title": "Easter College",
        "slug": "easter-college",
        "region": "Benguet",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Puerto Princesa City, Palawan",
        "title": "Palawan State University",
        "slug": "palawan-state-university",
        "region": "Palawan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Tagbilaran City, Bohol",
        "title": "University of Bohol",
        "slug": "university-of-bohol",
        "region": "Bohol",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Koronadal City, South Cotabato",
        "title": "Notre Dame of Marbel University",
        "slug": "notre-dame-of-marbel-university",
        "region": "South Cotabato",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, San Pablo City, Laguna",
        "title": "Laguna College",
        "slug": "laguna-college",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Naga City, Camarines Sur",
        "title": "Universidad de Sta. Isabel",
        "slug": "universidad-de-sta-isabel",
        "region": "Camarines Sur",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Legazpi City, Albay",
        "title": "Divine Word College of Legazpi",
        "slug": "divine-word-college-of-legazpi",
        "region": "Albay",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Naga City, Camarines Sur",
        "title": "Naga College Foundation",
        "slug": "naga-college-foundation",
        "region": "Camarines Sur",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Cebu City, Cebu",
        "title": "Cebu Institute of Technology University",
        "slug": "cebu-institute-of-technology",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State College, Olongapo City, Zambales",
        "title": "Gordon College",
        "slug": "gordon-college",
        "region": "Zambales",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Catbalogan City, Samar",
        "title": "Samar State University",
        "slug": "samar-state-university",
        "region": "Samar",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Lipa City, Batangas",
        "title": "De La Salle Lipa",
        "slug": "de-la-salle-lipa",
        "region": "Batangas",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Mati City, Davao Oriental",
        "title": "Davao Oriental State College of Science and Technology",
        "slug": "davao-oriental-state-college-of-science-and-technology",
        "region": "Davao Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "Gallego Foundation Colleges",
        "slug": "gallego-foundation-colleges",
        "region": "Nueva Ecija",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Catholic Private University, Cebu City, Cebu",
        "title": "University of San Jose-Recoletos",
        "slug": "university-of-san-jose-recoletos",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Lucena City, Quezon",
        "title": "Manuel S. Enverga University Foundation",
        "slug": "manuel-s-enverga-university-foundation",
        "region": "Quezon",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Cotabato City, Cotabato",
        "title": "Cotabato Medical Foundation College Inc.",
        "slug": "cotabato-medical-foundation-college-inc",
        "region": "Cotabato",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Zamboanga City, Zamboanga del Sur",
        "title": "Universidad de Zamboanga",
        "slug": "universidad-de-zamboanga",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE"
        ]
    },
    {
        "location": "Private University, Dumaguete City, Negros Oriental",
        "title": "Foundation University",
        "slug": "foundation-university",
        "region": "Negros Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Pasig City, Metro Manila",
        "title": "Arellano University in Pasig",
        "slug": "arellano-university-in-pasig",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Balanga City, Bataan",
        "title": "Bataan Peninsula State University - Main Campus",
        "slug": "bataan-peninsula-state-university-balanga-campus",
        "region": "Bataan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Cabanatuan City, Nueva Ecija",
        "title": "Nueva Ecija University of Science and Technology - Main",
        "slug": "nueva-ecija-university-of-science-and-technology",
        "region": "Nueva Ecija",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "La Fortuna College",
        "slug": "la-fortuna-college",
        "region": "Nueva Ecija",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Digos City, Davao del Sur",
        "title": "Polytechnic College of Davao Del Sur Inc.",
        "slug": "polytechnic-college-of-davao-del-sur-inc",
        "region": "Davao del Sur",
        "courses": [
            "NLE",
            "RTLE"
        ]
    },
    {
        "location": "State University, Tabaco City, Albay",
        "title": "Bicol University - Tabaco",
        "slug": "bicol-university-tabaco",
        "region": "Albay",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Santiago City, Isabela",
        "title": "University of La Salette Santiago",
        "slug": "university-of-la-salette-santiago",
        "region": "Isabela",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Catholic Private University, Tagbilaran City, Bohol",
        "title": "Holy Name University",
        "slug": "holy-name-university",
        "region": "Bohol",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Solano, Nueva Vizcaya",
        "title": "Aldersgate College",
        "slug": "aldersgate-college",
        "region": "Nueva Vizcaya",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Malolos City, Bulacan",
        "title": "La Consolacion University Philippines",
        "slug": "university-of-regina-carmeli",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Sison, Pangasinan",
        "title": "Northern Luzon Adventist College",
        "slug": "northern-luzon-adventist-college",
        "region": "Pangasinan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, La Union",
        "title": "Lorma Colleges",
        "slug": "lorma-colleges",
        "region": "La Union",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Dagupan City, Pangasinan",
        "title": "Colegio de Dagupan",
        "slug": "colegio-de-dagupan",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Legazpi City, Albay",
        "title": "Bicol University - Main Campus",
        "slug": "bicol-university-legazpi",
        "region": "Albay",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Buug, Zamboanga Sibugay",
        "title": "Mindanao State University - Buug Campus",
        "slug": "mindanao-state-university-buug-college",
        "region": "Zamboanga Sibugay",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Ilagan, Isabela",
        "title": "Isabela State University - Ilagan Campus",
        "slug": "isabela-state-university-in-ilagan",
        "region": "Isabela",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Tuguegarao City, Cagayan",
        "title": "Medical Colleges of Northern Philippines",
        "slug": "medical-colleges-of-northern-philippines",
        "region": "Cagayan",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Mary Chiles College",
        "slug": "mary-chiles-college-of-nursing",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Olongapo City, Zambales",
        "title": "Columban College",
        "slug": "columban-college",
        "region": "Zambales",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Catarman, Samar",
        "title": "University of Eastern Philippines",
        "slug": "university-of-eastern-philippines",
        "region": "Samar",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Pedro, Laguna",
        "title": "Laguna Northwestern College",
        "slug": "laguna-northwestern-college",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Naval, Biliran",
        "title": "Biliran Province State University",
        "slug": "naval-state-university",
        "region": "Biliran",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Kalibo, Aklan",
        "title": "Saint Gabriel College",
        "slug": "saint-gabriel-college",
        "region": "Aklan",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Marawi City, Lanao del Sur",
        "title": "Mindanao State University - Main Campus",
        "slug": "mindanao-state-university",
        "region": "Lanao del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Zamboanga City, Zamboanga del Sur",
        "title": "Brent Hospital and Colleges Inc.",
        "slug": "brent-hospital-and-colleges-inc",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Dapitan City, Zamboanga del Norte",
        "title": "Jose Rizal Memorial State University",
        "slug": "jose-rizal-memorial-state-university",
        "region": "Zamboanga del Norte",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Las Pinas, Metro Manila",
        "title": "Southville International School and Colleges",
        "slug": "southville-international-school-and-colleges",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Pagadian City, Zamboanga del Sur",
        "title": "Josefina H. Cerilles State College - Pagadian",
        "slug": "josefina-h-cerilles-state-college-pagadian",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Laoag City, Ilocos Norte",
        "title": "Divine Word College of Laoag",
        "slug": "divine-word-college-of-laoag",
        "region": "Ilocos Norte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Tuguegarao City, Cagayan",
        "title": "St. Paul University Philippines",
        "slug": "st-paul-university-philippines",
        "region": "Cagayan",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "Salazar Colleges of Science and Institute of Technology",
        "slug": "salazar-colleges-of-science-and-institute-of-technology",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Tanauan City, Batangas",
        "title": "First Asia Institute of Technology and Humanities",
        "slug": "first-asia-institute-of-technology-and-humanities",
        "region": "Batangas",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Bocaue, Bulacan",
        "title": "Dr. Yanga's College, Inc.",
        "slug": "dr-yangas-college-inc",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Norzagaray, Bulacan",
        "title": "Norzagaray College",
        "slug": "norzagaray-college",
        "region": "Bulacan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Balanga City, Bataan",
        "title": "Tomas del Rosario College",
        "slug": "tomas-del-rosario-college",
        "region": "Bataan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "St. Paul College Foundation - Cebu",
        "slug": "saint-paul-college-foundation-cebu",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "Velez College",
        "slug": "velez-college",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Bangued, Abra",
        "title": "Abra Valley Colleges",
        "slug": "abra-valley-colleges",
        "region": "Abra",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Unciano College Manila",
        "slug": "unciano-colleges-and-general-hospital",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Nasugbu, Batangas",
        "title": "Isabelo B. Calingasan Memorial Institute",
        "slug": "isabelo-b-calingasan-memorial-institute",
        "region": "Batangas",
        "courses": [
            "NLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Bongao, Tawi-Tawi",
        "title": "Abubakar Computer Learning Center Foundation",
        "slug": "abubakar-computer-learning-center-foundation",
        "region": "Tawi-Tawi",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Iriga City, Camarines Sur",
        "title": "University of Saint Anthony",
        "slug": "university-of-saint-anthony",
        "region": "Camarines Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Taytay, Rizal",
        "title": "Siena College of Taytay",
        "slug": "siena-college-of-taytay",
        "region": "Rizal",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "Asian College of Technology",
        "slug": "asian-college-of-technology",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Cabanatuan City, Nueva Ecija",
        "title": "Nueva Ecija Colleges",
        "slug": "nueva-ecija-colleges",
        "region": "Nueva Ecija",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, Pampanga",
        "title": "Central Luzon College - Pampanga",
        "slug": "celtech-colleges",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Cavite City, Cavite",
        "title": "San Sebastian College-Recoletos de Cavite",
        "slug": "san-sebastian-college-recoletos-de-cavite",
        "region": "Cavite",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Borongan City, Eastern Samar",
        "title": "Eastern Samar State University",
        "slug": "eastern-samar-state-university",
        "region": "Eastern Samar",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Calamba City, Laguna",
        "title": "Calamba Doctors' College",
        "slug": "calamba-doctors-college",
        "region": "Laguna",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "Mary the Queen College of Science and Technology",
        "slug": "mary-the-queen-college-of-science-and-technology",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Agoo, La Union",
        "title": "Don Mariano Marcos Memorial State University - South",
        "slug": "don-mariano-marcos-memorial-state-university-south-la-union",
        "region": "La Union",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Tagbilaran City, Bohol",
        "title": "BIT International College - Tagbilaran Campus",
        "slug": "bit-international-college-tagbilaran-campus",
        "region": "Bohol",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Pagadian City, Zamboanga del Sur",
        "title": "Medina College",
        "slug": "medina-college",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "State College, Isabela, Basilan",
        "title": "Basilan State College",
        "slug": "basilan-state-college",
        "region": "Basilan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Lucena City, Quezon",
        "title": "St. Anne College of Lucena",
        "slug": "st-anne-college-of-lucena",
        "region": "Quezon",
        "courses": [
            "NLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "State University, Lamut, Ifugao",
        "title": "Ifugao State University",
        "slug": "ifugao-state-university-main-campus",
        "region": "Ifugao",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Makati, Metro Manila",
        "title": "AMA Computer University - Makati Campus",
        "slug": "ama-computer-university-makati-campus",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Caritas Family Hospital and Colleges",
        "slug": "caritas-fci-hospital-and-colleges",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, San Fernando City, Pampanga",
        "title": "University of the Assumption",
        "slug": "university-of-the-assumption",
        "region": "Pampanga",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Municipal University, Urdaneta City, Pangasinan",
        "title": "Urdaneta City University",
        "slug": "urdaneta-city-university",
        "region": "Pangasinan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Municipal University, Makati, Metro Manila",
        "title": "University of Makati",
        "slug": "university-of-makati",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Pasay, Metro Manila",
        "title": "Manila Adventist College",
        "slug": "manila-adventist-medical-center-and-colleges",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Bontoc, Mountain Province",
        "title": "Mountain Province State University",
        "slug": "mountain-province-state-polytechnic-college-main",
        "region": "Mountain Province",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Boac, Marinduque",
        "title": "Marinduque State College",
        "slug": "marinduque-state-college",
        "region": "Marinduque",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "Philippine Rehabilitation Institute",
        "slug": "philippine-rehabilitation-institute",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Kabacan, Cotabato",
        "title": "University of Southern Mindanao",
        "slug": "university-of-southern-mindanao",
        "region": "Cotabato",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Mandaue City, Cebu",
        "title": "University of Cebu - LapuLapu and Mandaue Campus",
        "slug": "university-of-cebu-lapulapu-and-mandaue-campus",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Maasin City, Southern Leyte",
        "title": "The College of Maasin",
        "slug": "the-college-of-maasin",
        "region": "Southern Leyte",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Lucena City, Quezon",
        "title": "Maryhill College",
        "slug": "maryhill-college",
        "region": "Quezon",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, La Union",
        "title": "La Finn's Scholastica",
        "slug": "la-union-colleges-of-nursing-arts-and-sciences",
        "region": "La Union",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Bayombong, Nueva Vizcaya",
        "title": "Saint Mary's University",
        "slug": "saint-mary-s-university",
        "region": "Nueva Vizcaya",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Santiago City, Isabela",
        "title": "Northeastern College",
        "slug": "northeastern-college",
        "region": "Isabela",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Polangui, Albay",
        "title": "Bicol University - Polangui Campus",
        "slug": "bicol-university-polangui",
        "region": "Albay",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Jolo, Sulu",
        "title": "Mindanao State University - Sulu",
        "slug": "mindanao-state-university-sulu",
        "region": "Sulu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Virac, Catanduanes",
        "title": "Catanduanes State University - Virac",
        "slug": "catanduanes-state-colleges",
        "region": "Catanduanes",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Dipolog City, Zamboanga del Norte",
        "title": "Dipolog Medical Center College Foundation",
        "slug": "dipolog-medical-center-college-foundation",
        "region": "Zamboanga del Norte",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Murcia, Negros Occidental",
        "title": "Central Philippine Adventist College",
        "slug": "central-philippine-adventist-college",
        "region": "Negros Occidental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Malaybalay City, Bukidnon",
        "title": "San Isidro College",
        "slug": "san-isidro-college",
        "region": "Bukidnon",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Tarlac City, Tarlac",
        "title": "Tarlac State University",
        "slug": "tarlac-state-university",
        "region": "Tarlac",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Silay City, Negros Occidental",
        "title": "Red Aeronautics and Technological Institute",
        "slug": "red-aeronautics-and-technological-institute-inc",
        "region": "Negros Occidental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Jose del Monte City, Bulacan",
        "title": "Colegio de San Gabriel Arcangel",
        "slug": "colegio-de-san-gabriel-arcangel",
        "region": "Bulacan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "State University, Bais, Negros Oriental",
        "title": "Negros Oriental State University - Bais Campus",
        "slug": "negros-oriental-state-university-bais-city-campus",
        "region": "Negros Oriental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Olongapo City, Zambales",
        "title": "George Dewey Medical College",
        "slug": "george-dewey-medical-college",
        "region": "Zambales",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Guagua, Pampanga",
        "title": "ACLC - Guagua",
        "slug": "ama-computer-learning-center-guagua",
        "region": "Pampanga",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Lamitan City, Basilan",
        "title": "Basilan State College - Lamitan",
        "slug": "basilan-state-college-lamitan",
        "region": "Basilan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Aesthetic Studies Institution, Quezon City, Metro Manila",
        "title": "International Academy for Aesthetic Sciences",
        "slug": "international-acadedemy-for-aesthetic-sciences",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Mandaue City, Cebu",
        "title": "University of the Visayas - Banilad Campus",
        "slug": "university-of-the-visayas-banilad-campus",
        "region": "Cebu",
        "courses": [
            "NLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Bayombong, Nueva Vizcaya",
        "title": "PLT College",
        "slug": "plt-college",
        "region": "Nueva Vizcaya",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "St. John Technological College of the Philippines",
        "slug": "st-john-technological-college-of-the-philippines",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, City of San Fernando, Pampanga",
        "title": "Our Lady of Fatima University in Pampanga",
        "slug": "east-central-colleges-pampanga",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "State Open University, Los Banos, Laguna",
        "title": "University of the Philippines - Open University",
        "slug": "university-of-the-philippines-open-university",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Baler, Aurora",
        "title": "Wesleyan University Philippines - Aurora",
        "slug": "wesleyan-university-philippines-aurora",
        "region": "Aurora",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Cadiz City, Negros Occidental",
        "title": "Northern Negros State College of Science and Technology - Cadiz",
        "slug": "northern-negros-state-college-of-science-and-technology-cadiz",
        "region": "Negros Occidental",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Bustus, Bulacan",
        "title": "Bulacan State University - Bustos Campus",
        "slug": "bulacan-state-university-in-bustus",
        "region": "Bulacan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Imus, Cavite",
        "title": "Imus Institute",
        "slug": "imus-institute",
        "region": "Cavite",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Jose, Antique",
        "title": "St. Anthony's College - San Jose",
        "slug": "st-anthony-s-college-san-jose-antique",
        "region": "Antique",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Muntinlupa City, Metro Manila",
        "title": "Saint Bernadette College of Alabang",
        "slug": "saint-bernadette-college-of-alabang",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Digos City, Davao del Sur",
        "title": "South Philippine Adventist College",
        "slug": "south-philippine-adventist-college-davao",
        "region": "Davao del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Guihulngan, Negros Oriental",
        "title": "Negros Oriental State University - Guihulngan Campus",
        "slug": "negros-oriental-state-university-guihulngan-campus",
        "region": "Negros Oriental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Olongapo City, Zambales",
        "title": "Mondriaan Aura College",
        "slug": "mondriaan-aura-college",
        "region": "Zambales",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Balanga City, Bataan",
        "title": "Philippine Women's University - CDCEC - Bataan",
        "slug": "philippine-womens-university-cdcec-bataan",
        "region": "Bataan",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, Pampanga",
        "title": "St. Nicolas College of Business & Technology",
        "slug": "st-nicolas-college-of-business-technology",
        "region": "Pampanga",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, San Fernando City, Pampanga",
        "title": "College Of Our Lady Of Mount Carmel",
        "slug": "college-of-our-lady-of-mount-carmel",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MTLE",
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Meycauayan City, Bulacan",
        "title": "Sta. Clara of Montefalco College",
        "slug": "sta-clara-of-montefalco-college",
        "region": "Bulacan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Guagua, Pampanga",
        "title": "Guagua National Colleges",
        "slug": "guagua-national-colleges",
        "region": "Pampanga",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private College, Tacloban, Leyte",
        "title": "JE Mondejar Foundation College",
        "slug": "je-mondejar-foundation-college",
        "region": "Leyte",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Makati, Metro Manila",
        "title": "Medici di Makati College",
        "slug": "medici-di-makati-college",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Las Pinas, Metro Manila",
        "title": "Centro Escolar Las Pinas",
        "slug": "centro-escolar-las-pinas",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Zamboanga City, Zamboanga del Sur",
        "title": "Universidad de Zamboanga - Pagadian",
        "slug": "universidad-de-zamboanga-pagadian",
        "region": "Zamboanga del Sur",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    /*{
        "location": "Private College, Bacolod City, Negros Occidental",
        "title": "STI West Negros University",
        "slug": "sti-college-bacolod",
        "region": "Negros Occidental",
        "courses": [
            "NLE"
        ]
    },*/
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "Saint Paul College Foundation Inc. - Bulacao Campus",
        "slug": "saint-paul-college-foundation-inc-bulacao-campus",
        "region": "Cebu",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Marawi City, Lanao del Sur",
        "title": "Bubong Marzok Memorial College Foundation",
        "slug": "bubong-marzok-memorial-college-foundation",
        "region": "Lanao del Sur",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State College, Maluso, Basilan",
        "title": "Basilan State College - Maluso",
        "slug": "basilan-state-college-maluso",
        "region": "Basilan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, San Ramon, Bataan",
        "title": "Bataan Peninsula State University - Dinalupihan Campus",
        "slug": "bataan-peninsula-state-university-dinalupihan-campus",
        "region": "Bataan",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Calamba City, Laguna",
        "title": "Saint John Colleges",
        "slug": "saint-john-colleges",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Mandaluyong, Metro Manila",
        "title": "St. Augustine School of Nursing - Mandaluyong Campus",
        "slug": "st-augustine-school-of-nursing-mandaluyong-campus",
        "region": "Metro Manila",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Cebu City, Cebu",
        "title": "University of Cebu",
        "slug": "university-of-cebu",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Cebu City, Cebu",
        "title": "Cebu Technological University",
        "slug": "cebu-technological-university",
        "region": "Cebu",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Subic Bay, Zambales",
        "title": "Ramon Magsaysay Technological University - Mondriaan Aura College",
        "slug": "ramon-magsaysay-technological-university-mondriaan-aura-college",
        "region": "Zambales",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "State University, Bayawan, Negros Oriental",
        "title": "Negros Oriental State University - Bayawan - Santa Catalina Campus",
        "slug": "negros-oriental-state-university-bayawan-campus",
        "region": "Negros Oriental",
        "courses": [
            "NLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Tarlac City, Tarlac",
        "title": "Philippine Women's University - CDCEC - Tarlac",
        "slug": "philippine-womens-university-cdcec-tarlac",
        "region": "Tarlac",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Davao City, Davao",
        "title": "Philippine College of Technology",
        "slug": "philippine-college-of-technology",
        "region": "Davao",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private College, Capas, Tarlac",
        "title": "Dominican College of Tarlac",
        "slug": "dominican-college-of-tarlac",
        "region": "Tarlac",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Binan City, Laguna",
        "title": "University of Perpetual Help System JONELTA in Biñan City",
        "slug": "university-of-perpetual-help-laguna",
        "region": "Laguna",
        "courses": [
            "NLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "University of Santo Tomas",
        "slug": "university-of-santo-tomas",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "Arellano University",
        "slug": "arellano-university",
        "region": "Metro Manila",
        "courses": [
            "NLE",
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private College, Taguig City, Metro Manila",
        "title": "Global City Innovative College",
        "slug": "global-city-innovative-college",
        "region": "Metro Manila",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College, Legazpi City, Albay",
        "title": "Ago Medical And Educational Center Bicol Christian College Of Medicine",
        "slug": "ago-medical-and-educational-center-bicol-christian-college-of-medicine",
        "region": "Albay",
        "courses": [
            "MTLE",
            "MLE",
            "DLE"
        ]
    },
    {
        "location": "Private College, Cainta, Rizal",
        "title": "ICCT Colleges Cainta",
        "slug": "icct-colleges",
        "region": "Rizal",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College, Las Pinas, Metro Manila",
        "title": "South SEED-LPDH College",
        "slug": "south-seed-lpdh-college",
        "region": "Metro Manila",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "State University, Caritan, Cagayan",
        "title": "Cagayan State University",
        "slug": "cagayan-state-university-caritan-campus",
        "region": "Cagayan",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College (Co-ed), Manila, Metro Manila",
        "title": "College of the Holy Spirit Manila",
        "slug": "college-of-the-holy-spirit",
        "region": "Metro Manila",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College, Kabankalan City, Negros Occidental",
        "title": "Fellowship Baptist College",
        "slug": "fellowship-baptist-college",
        "region": "Negros Occidental",
        "courses": [
            "MTLE",
            "MLE"
        ]
    },
    {
        "location": "Private University, Davao City, Davao",
        "title": "Lyceum of the Philippines - Davao Campus",
        "slug": "lyceum-of-the-philippines-davao-campus",
        "region": "Davao",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private University, General Trias, Cavite",
        "title": "Lyceum of the Philippines University - Cavite Campus",
        "slug": "lyceum-of-the-philippines-university-cavite-campus",
        "region": "Cavite",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College, Manila, Metro Manila",
        "title": "Metropolitan Medical Center - College of Arts, Science & Technology",
        "slug": "metropolitan-hospital-college-of-nursing",
        "region": "Metro Manila",
        "courses": [
            "MTLE"
        ]
    },
    {
        "location": "Private College, Butuan City, Agusan del Norte",
        "title": "Asian College Foundation",
        "slug": "asian-college-foundation",
        "region": "Agusan del Norte",
        "courses": [
            "RTLE",
            "MLE"
        ]
    },
    {
        "location": "State College, Tangub City, Misamis Occidental",
        "title": "Gov. Alfonso D. Tan College",
        "slug": "gov-alfonso-d-tan-college",
        "region": "Misamis Occidental",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Quezon City, Metro Manila",
        "title": "Saint Francis Divine College",
        "slug": "saint-francis-divine-college",
        "region": "Metro Manila",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State University, Tabuk City, Kalinga",
        "title": "Kalinga State University",
        "slug": "kalinga-apayao-state-college",
        "region": "Kalinga",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Catholic Private College, Dumaguete City, Negros Oriental",
        "title": "Colegio de Santa Catalina de Alejandria",
        "slug": "colegio-de-santa-catalina-de-alejandria",
        "region": "Negros Oriental",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State College, Estancia, Iloilo",
        "title": "Northern Iloilo Polytechnic State College",
        "slug": "northern-iloilo-polytechnic-state-college-estancia",
        "region": "Iloilo",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Community College, Pangil, Laguna",
        "title": "Balian Community College",
        "slug": "balian-community-college",
        "region": "Laguna",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Masbate City, Masbate",
        "title": "Masbate College",
        "slug": "masbate-college",
        "region": "Masbate",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State College, Candon City, Ilocos Sur",
        "title": "North Luzon Philippines State College",
        "slug": "north-luzon-philippines-state-college",
        "region": "Ilocos Sur",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Kabankalan City, Negros Occidental",
        "title": "Southland College",
        "slug": "southland-college",
        "region": "Negros Occidental",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Cebu City, Cebu",
        "title": "Cebu School of Midwifery",
        "slug": "cebu-school-of-midwifery",
        "region": "Cebu",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private University, Baguio City, Benguet",
        "title": "Philippine Women's University - CDCEC - Baguio",
        "slug": "philippines-womens-university-cdcec-baguio",
        "region": "Benguet",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State College, San Jose, Occidental Mindoro",
        "title": "Occidental Mindoro State College",
        "slug": "occidental-mindoro-state-college",
        "region": "Occidental Mindoro",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State University, Potia, Ifugao",
        "title": "Ifugao State University - Potia Campus",
        "slug": "ifugao-state-university-potia-campus",
        "region": "Ifugao",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Mabalacat, Pampanga",
        "title": "Jose C. Feliciano College",
        "slug": "jose-c-feliciano-college-dau-mabalacat-pampanga",
        "region": "Pampanga",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "State University, Calape, Bohol",
        "title": "Bohol Island State University - Calape Campus",
        "slug": "bohol-island-state-university-calape-campus",
        "region": "Bohol",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Valencia City, Bukidnon",
        "title": "San Agustin Institute of Technology",
        "slug": "san-agustin-institute-of-technology",
        "region": "Bukidnon",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, San Jose del Monte City, Bulacan",
        "title": "Golden Valley College",
        "slug": "golden-valley-college",
        "region": "Bulacan",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private University, Malabon City, Metro Manila",
        "title": "Arellano University in Malabon",
        "slug": "arellano-university-in-malabon",
        "region": "Metro Manila",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Paete, Laguna",
        "title": "Eastern Laguna Colleges",
        "slug": "eastern-laguna-colleges",
        "region": "Laguna",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Baliuag, Bulacan",
        "title": "Fernandez College of Arts and Technology",
        "slug": "fernandez-college-of-arts-and-technology",
        "region": "Bulacan",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private College, Calamba City, Laguna",
        "title": "Philippine Women's University - CDCEC - Calamba",
        "slug": "philippine-womens-university-cdcec-calamba-city",
        "region": "Laguna",
        "courses": [
            "MLE"
        ]
    },
    {
        "location": "Private University, Manila, Metro Manila",
        "title": "University of the East",
        "slug": "university-of-the-east",
        "region": "Metro Manila",
        "courses": [
            "DLE"
        ]
    },
    {
        "location": "Private University, Sagay, Negros Occidental",
        "title": "State University of Northern Negros",
        "slug": "state-university-of-northern-negros",
        "region": "Negros Occidental",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private College, Sagay City, Negros Occidental",
        "title": "Colegio San Agustin-Bacolod",
        "slug": "colegio-san-agustin-bacolod",
        "region": "Negros Occidental",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private University, Bacolod, Negros Occidental",
        "title": "University of St. La Salle-Bacolod",
        "slug": "university-of-st-la-salle-bacolod",
        "region": "Negros Occidental",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private University, Bacolod, Negros Occidental",
        "title": "University of Negros Occidental - Recoletos",
        "slug": "university-of-negros-occidental-recoletos",
        "region": "Negros Occidental",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private College, Puerto Princesa, Palawan",
        "title": "Palawan Polytechnic College Inc.",
        "slug": "palawan-polytechnic-college-inc",
        "region": "Palawan",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private University, Banga, Aklan",
        "title": "Aklan State University",
        "slug": "aklan-state-university",
        "region": "Aklan",
        "courses": [
            "*"
        ]
    },
    {
        "location": "Private University, Roxas City, Capiz",
        "title": "Filamer Christian University, Inc.",
        "slug": "filamer-christian-university-inc",
        "region": "Capiz",
        "courses": [
            "*"
        ]
    }
];