<template>
	<div>
		<h2 class="content-block">
			<img src="@/assets/reg_form_header.png" class="header-image" />
			<div class="header-title">Alumni Registration Form</div>
		</h2>

		<div class="content-block dx-card responsive-paddings application-form">
			<form @submit.prevent="submitApplicationForm" enctype="multipart/form-data">
				<DxForm id="alumni-dxform" label-mode="floating" :col-count="3" :form-data="applicationData">
					<template #groupHeading="{ data }">
						<i :class="'dx-icon dx-icon-' + data.caption.split(';')[0]"/><span>{{ data.caption.split(';')[1] }}</span>
					</template>
					<DxSimpleItem :col-span="3">
						<DxBox direction="row" width="100%" class="simple-item-descriptive dx-card dx-theme-accent-as-border-color">
							<DxItem :ratio="1">
								<p>Thank you for your interest! This survey will not take more than 10 minutes, but it can change your life or help your fellow nurses who are at the same step to enter the work life in PLNE phase.</p>
							</DxItem>
						</DxBox>
					</DxSimpleItem>
					<DxGroupItem caption="user;Name and personal details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="last_name" :is-required="true" :label="{ text: 'Last/Family name' }" />
						<DxSimpleItem data-field="first_name" :is-required="true" />
						<DxSimpleItem data-field="middle_name" :is-required="true" />
						<DxSimpleItem data-field="gender" editor-type="dxSelectBox" :editor-options="genderListOptions" :is-required="true" />
						<DxSimpleItem data-field="birthday" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native', max: Date() }" :is-required="true" />
					</DxGroupItem>
					<DxGroupItem caption="tel;Contact details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="email" :is-required="true">
							<DxRequiredRule message="Email is required"/><DxEmailRule message="Email is invalid"/>
						</DxSimpleItem>
						<DxSimpleItem data-field="facebook" :is-required="false" :label="{ text: 'Facebook Messenger' }" />
						<DxEmptyItem />
						<DxSimpleItem data-field="address" :is-required="false" :col-span="2" :label="{ text: 'Current Address' }" editor-type="dxTextArea" :editor-options="{ autoResizeEnabled: true, maxHeight: '180px' }" />
					</DxGroupItem>
					<DxGroupItem caption="runner;Graduation details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="graduationSchool" :is-required="true" :label="{ text: 'School graduated at' }" editor-type="dxSelectBox" :editor-options="graduatedSchoolOptions" />
						<DxSimpleItem data-field="graduationYear" :is-required="true" :label="{ text: 'Year of graduation' }" editor-type="dxSelectBox" :editor-options="graduatedYearOptions" />
					</DxGroupItem>
					<DxGroupItem caption="toolbox;First employment" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="employFirst" :is-required="true" :label="{ text: 'First employment' }" editor-type="dxSelectBox" :editor-options="firstEmploymentOptions" :col-span="3" />
						<DxSimpleItem data-field="employSupport" :is-required="true" :label="{ text: 'Support for your first employment' }" editor-type="dxSelectBox" :editor-options="firstEmploymentSupportOptions" :col-span="3" />
						<DxSimpleItem data-field="employSupportDetail" :is-required="true" :col-span="3"
							:label="{ text: applicationData.employSupport == 'yes' ? 'What would have helped you (what did you miss)?' : 'Who/what helped you find a job?' }" />
					</DxGroupItem>
					<DxGroupItem caption="event;Total work experience since PNLE" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="totalWorkYears" :is-required="true" :label="{ text: 'Years experience' }" editor-type="dxNumberBox" :editor-options="{ min: 0, max: 100 }" />
						<DxSimpleItem data-field="totalWorkMonths" :is-required="true" :label="{ text: 'Months experience' }" editor-type="dxNumberBox" :editor-options="{ min: 0, max: 12 }" />
					</DxGroupItem>

					<DxGroupItem caption=";What is your current employment status?" caption-template="groupHeading" :col-span="3" :col-count="2">
						<DxButtonItem>
							<DxButtonOptions text="I am currently employed" icon="taskcomplete" width="100%" :onClick="currentJobStatusSet"
								:type="currentJobStatus == 'yes' ? 'default' : 'normal'" :height="currentJobStatus != '' ? '40px' : '100px'"
							/>
						</DxButtonItem>
						<DxButtonItem>
							<DxButtonOptions text="I am currently unemployed" icon="taskcomplete" width="100%" :onClick="currentJobStatusSet"
								:type="currentJobStatus == 'no' ? 'default' : 'normal'" :height="currentJobStatus != '' ? '40px' : '100px'"
							/>
						</DxButtonItem>
					</DxGroupItem>

					<DxGroupItem v-if="currentJobStatus == 'yes'" caption=";Employer Details" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="employerSite" editor-type="dxSelectBox" :editor-options="employerSiteOptions" :is-required="true" />
						<DxSimpleItem v-if="applicationData.employerSite == 'national'" :is-required="applicationData.employerSite == 'national'"
							data-field="employerRegion" editor-type="dxSelectBox" :editor-options="employerSiteNational" :col-span="2"  />
						<DxSimpleItem v-if="applicationData.employerSite == 'international'" :is-required="applicationData.employerSite == 'international'"
							data-field="employerCountry" editor-type="dxSelectBox" :editor-options="employerSiteInternational" :col-span="2" />
						<DxEmptyItem v-if="!applicationData.employerSite" :col-span="2" />
						<DxSimpleItem data-field="employerKind" editor-type="dxSelectBox" :editor-options="employerKindOptions" :is-required="true" :col-span="1" />
						<DxSimpleItem v-if="needsEmployerKindSpecifics" :is-required="needsEmployerKindSpecifics ? true : false" data-field="employerKindSpecifics" :label="{ text: needsEmployerKindSpecifics }" :col-span="2" />
					</DxGroupItem>

					<DxGroupItem v-if="currentJobStatus == 'yes'" caption=";Job Specifics" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="jobPosition" editor-type="dxSelectBox" :editor-options="jobPositionOptions" :is-required="true" />
						<DxSimpleItem data-field="jobPositionArea" :is-required="true" :label="{ text: 'Preferred area/unit/job description' }" :col-span="2" />
						<DxSimpleItem data-field="jobDurationEnds" editor-type="dxDateBox" :editor-options="{ type: 'date', pickerType: 'native' }" :is-required="true" :label="{ text: 'End date of employment contract' }" />
						<DxSimpleItem data-field="jobIntent" editor-type="dxSelectBox" :editor-options="jobIntentOptions" :label="{ text: 'Your intention after' }" :is-required="true" :col-span="2" />
						<DxSimpleItem v-if="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics" 
							data-field="jobPreferEmployerKind" :label="{ text: 'What kind of employer would you prefer?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerKindOptions" />
						<DxSimpleItem v-if="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics" 
							data-field="jobPreferEmployerSite" :label="{ text: 'What is your prefered choice of employer site?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteOptions" />
						<DxSimpleItem v-if="needsJobPrefSpecifics && applicationData.jobPreferEmployerSite == 'national'" :is-required="needsJobPrefSpecifics && applicationData.jobPreferEmployerSite == 'national'"
							data-field="jobPreferEmployerRegion" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteNational" :col-span="2" :label="{ text: 'Prefered Region' }" />
						<DxSimpleItem v-if="needsJobPrefSpecifics && applicationData.jobPreferEmployerSite == 'international'" :is-required="needsJobPrefSpecifics && applicationData.jobPreferEmployerSite == 'international'"
							data-field="jobPreferEmployerCountry" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteInternational" :col-span="2" :label="{ text: 'Prefered Country' }" />
						<DxEmptyItem v-if="!applicationData.jobPreferEmployerSite" />
						<DxSimpleItem v-if="needsJobPrefSpecifics" :is-required="needsJobPrefSpecifics"
							data-field="jobPreferEmployerPosition" editor-type="dxSelectBox" :editor-options="jobPreferEmployerPositionOptions" :col-span="2" :label="{ text: 'Prefered Position' }" />
					</DxGroupItem>
					<DxGroupItem v-if="currentJobStatus == 'no'" caption=";Job Application" caption-template="groupHeading" :col-span="3" :col-count="3">
						<DxSimpleItem data-field="employmentPlans" editor-type="dxSelectBox" :editor-options="employmentPlansOptions" :is-required="true" :col-span="3" />
						<DxSimpleItem 
							data-field="jobPreferEmployerSite" :label="{ text: 'What is your prefered choice of employer site?' }" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteOptions" />
						<DxSimpleItem v-if="applicationData.jobPreferEmployerSite == 'national'" :is-required="applicationData.jobPreferEmployerSite == 'national'"
							data-field="jobPreferEmployerRegion" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteNational" :col-span="2" :label="{ text: 'Prefered Region' }" />
						<DxSimpleItem v-if="applicationData.jobPreferEmployerSite == 'international'" :is-required="applicationData.jobPreferEmployerSite == 'international'"
							data-field="jobPreferEmployerCountry" editor-type="dxSelectBox" :editor-options="jobPreferEmployerSiteInternational" :col-span="2" :label="{ text: 'Prefered Country' }" />
						<DxEmptyItem v-if="!applicationData.jobPreferEmployerSite" :col-span="2" />
						<DxSimpleItem data-field="jobPreferEmployerPosition" editor-type="dxSelectBox" :editor-options="jobPreferEmployerPositionOptions" :col-span="3" :label="{ text: 'Prefered Job Position' }" />
					</DxGroupItem>
					
					<DxGroupItem :col-span="3" :col-count="3" v-if="currentJobStatus">
						<DxSimpleItem :col-span="2">
							<template #default>
								<DxBox direction="row">
									<DxItem :ratio="0" :baseSize="40">
										<DxCheckBox v-model:value="applicationData.allowProfileMatching" />
									</DxItem>
									<DxItem :ratio="1">
										Thank you for answering the questions. In the case that aiding tools or a platform for finding the dream employment site/employer of every nurse (matching platform) are developed from results out of this survey, I would like to have access to these tools or position my profile on the matching platform.
									</DxItem>
								</DxBox>
							</template>
						</DxSimpleItem>
						<DxButtonItem v-if="currentJobStatus != ''" :col-span="3" :button-options="{ type: 'success', text: 'Submit Alumni Form', useSubmitBehavior: true }" />
					</DxGroupItem>
				</DxForm>
			</form>
			<DxLoadPanel :position="{ of: '.application-form' }" :shading="true" shadingColor="rgba(0,0,0,0.4)" v-model:visible="loadingVisible" />
		</div>
	</div>
</template>

<script>
 /* eslint-disable */
import { DxForm, DxSimpleItem , DxGroupItem, DxButtonItem, DxButtonOptions, DxEmptyItem, DxRequiredRule, DxEmailRule } from "devextreme-vue/form";
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxBox, DxItem } from 'devextreme-vue/box';
import { inject, ref, computed } from "vue";
import to from 'await-to-js';
import countries from '../countries.js';

export default {
	setup() {
		const $http = inject('$http');
		const colCountByScreen = {
			xs: 1,
			sm: 2,
			md: 3,
			lg: 4
		}

		const currentJobStatus = ref('');			// Values: '', 'yes' or 'no'
		const currentJobStatusSet = function(ev) {
			console.log(ev);
			let newValue = ev.element.innerText.toLowerCase().includes('unemployed') ? 'no' : 'yes';				// TODO: Oh god, figure out a better way!
			currentJobStatus.value = newValue == currentJobStatus.value ? '' : newValue;		// Click to cancel status
			return false;
		}

		const applicationData = ref({
			allowProfileMatching: true,
		});
		let loadingVisible = ref(false);

		const genderListOptions = { dataSource: [ 'Male', 'Female', 'Diverse' ] };
		const schoolsList = [ 'ILOILO', 'BACOLOD', 'PALAWAN', 'AKLAN', 'ROXAS', 'ANTIQUE', 'TUGUEGARAO', 'DIPOLOG', 'MANILA', 'DAVAO', 'GENSAN', 'NUEVA VIZCAYA' ];
		const graduatedSchoolOptions = { dataSource: schoolsList };
		const graduatedYearOptions = { dataSource: [], searchEnabled: true };
		const upToYear = (new Date()).getFullYear();
		for (var i = upToYear; i >= 1900; i--) {
			graduatedYearOptions.dataSource.push(i);
		}
		const firstEmploymentOptions = { displayExpr: 'text', valueExpr: 'id', dataSource: [ 
			{ id: 'already', text: "I already had an employment contract/job offer before PNLE" },
			{ id: 'searched', text: "I had to search for employment after PNLE" },
			{ id: 'none', text: "I couldn't find any employment as of up to now" },
		]};
		const firstEmploymentSupportOptions = { displayExpr: 'text', valueExpr: 'id', onValueChanged: (e) => {
		}, dataSource: [ 
			{ id: 'yes', text: "I missed the support in finding an employer for my first job" },
			{ id: 'no', text: "I did not miss the support in finding an employer for my first job" },
		]};

		const employerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
		const employerSiteNational = { dataSource: [ 'Region A', 'Region B', 'Region C' ] };
		const employerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
		const employerKindOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' } ] },
				{ key: "", items: [
					{ id: "community", name: "Community" },
					{ id: "company-school", name: "Company/School" },
					{ id: "nursing-home", name: "Nursing home" },
					{ id: "elderly-home", name: "Elderly home" },
					{ id: "non-sector", name: "Non health-care sector" },
					{ id: "other", name: "Other" },
				] },
			],
		};
		const needsEmployerKindSpecifics = computed(() => {
			let otherLabel = 'employerKind' in applicationData.value ? String(applicationData.value['employerKind']) : null;
			if (!otherLabel)
				return false;
			if (otherLabel == 'non-sector')
				return "Other sector of employer";
			if (otherLabel == 'other')
				return "Other type of employer";
			return false;
		});
		const jobPositionOptions = {
			dataSource: [ { id: 'no-contact', name: 'No direct contact with patients' }, { id: 'ambulatory', name: 'Direct contact with Ambulatory patients' }, { id: 'stationary', name: 'Direct contact with stationary patients' } ],
			displayExpr: 'name', valueExpr: 'id',
		};

		const jobIntentOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: "Do you intent to stay with this employer for a long time or do you aim for another destination?", items: [
					{ id: "long", name: "I intend to stay with this employer longer than 2 years" },
					{ id: "short", name: "I plan to stay in this job for a maximum of 2 years and then I would like a new job opportunity." },
				] },
			],
		}
		const needsJobPrefSpecifics = computed(() => {
			return 'jobIntent' in applicationData.value && String(applicationData.value['jobIntent']) == 'short';
		});

		const jobPreferEmployerKindOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: "Hospital", items: [ { id: 'hospital-primary', name: 'Primary Hospital' }, { id: 'hospital-secondary', name: 'Secondary Hospital' }, { id: 'hospital-tertiary', name: 'Tertiary Hospital' }, { id: 'hospital-any', name: 'Any Hospital (does not matter)' } ] },
				{ key: "", items: [
					{ id: "community", name: "Community" },
					{ id: "company-school", name: "Company/School" },
					{ id: "nursing-home", name: "Nursing home" },
					{ id: "elderly-home", name: "Elderly home" },
					{ id: "non-sector", name: "Non health-care sector" },
					{ id: "other", name: "Other" },
				] },
			],
		}

		const jobPreferEmployerSiteOptions = { dataSource: [ { val: 'national', label: 'National Employer' }, { val: 'international', label: 'International Employer' } ], valueExpr: 'val', displayExpr: 'label' };
		const jobPreferEmployerSiteNational = { dataSource: [ 'Region A', 'Region B', 'Region C' ] };
		const jobPreferEmployerSiteInternational = { dataSource: countries, valueExpr: 'code', displayExpr: 'name', searchEnabled: true };
		const jobPreferEmployerPositionOptions = {
			dataSource: [ { id: 'no-contact', name: 'No direct contact with patients' }, { id: 'ambulatory', name: 'Direct contact with Ambulatory patients' }, { id: 'stationary', name: 'Direct contact with stationary patients' } ],
			displayExpr: 'name', valueExpr: 'id',
		};

		const employmentPlansOptions = {
			grouped: true,
			displayExpr: 'name',
			valueExpr: 'id',
			dataSource: [
				{ key: "I have applied for a job", items: [
					{ id: "applied-examwait", name: "Not yet but I will soon after passing the exam" },
					{ id: "applied-already-national", name: "Yes, I applied for a job nationally but without success/waiting for feedback" },
					{ id: "applied-already-international", name: "Yes, I applied for a job internationally but without success/waiting for feedback" },
					{ id: "not-applied", name: "I won't apply for a job" },
				] },
			],
		};

		const submitApplicationForm = async () => {
			alert("Submitted!");
			let returnVal, err;
			loadingVisible.value = true;
			[ err, returnVal ] = await to($http.post('/alumniRegister', applicationData.value));
			loadingVisible.value = false;
			if (err) {
				console.error(err);
				return;
			}
			console.log(returnVal);
		}
		
		return {
			colCountByScreen,
			applicationData,
			submitApplicationForm,
			loadingVisible,
			genderListOptions, graduatedSchoolOptions, graduatedYearOptions, firstEmploymentOptions, firstEmploymentSupportOptions,
			currentJobStatus, currentJobStatusSet,
			employerSiteOptions, employerSiteNational, employerSiteInternational,
			employerKindOptions, needsEmployerKindSpecifics,
			jobPositionOptions, jobIntentOptions,
			needsJobPrefSpecifics, jobPreferEmployerKindOptions, jobPreferEmployerSiteOptions, jobPreferEmployerSiteNational, jobPreferEmployerSiteInternational, jobPreferEmployerPositionOptions,
			employmentPlansOptions,
		};
	},
	components: {
		DxForm, DxSimpleItem, DxGroupItem, DxButtonItem, DxButtonOptions, DxEmptyItem, DxRequiredRule, DxEmailRule, DxLoadPanel, DxBox, DxItem, DxCheckBox,
		DxTextArea, DxSelectBox, DxDateBox // eslint-disable-line vue/no-unused-components
	}
};
</script>

<style>
.header-image {
	width: 100%;
	max-width: 1200px;
}
.header-title {
	text-align: center;
	text-transform: uppercase;
	width: 100%; max-width: 1200px;
	border-bottom: 4px double black;
}
.header-subtitle {
	text-align: center;
	width: 100%; max-width: 1200px;
	font-size: 20px;
}
.application-form {
	max-width: 1200px;
}
.simple-item-descriptive {
	padding: 0 20px;
	border-left: 6px solid;
	text-align: justify;
}
.simple-item-descriptive p {
	padding-right: 15px;
}
.simple-item-descriptive p+p {
	margin-top: 0;
}
.simple-item-videobox {
	display: flex;
	justify-content: center;
	color: #efefef;
	margin: 10px;
	font-size: 24px;
	text-align: center;
	background: #ccc;
	border: 2px solid #aaa;
}
</style>
