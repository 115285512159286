export default [
	{ code: "PH-14", name: "Autonomous Region in Muslim Mindanao" },
	{ code: "PH-05", name: "Bicol" },
	{ code: "PH-02", name: "Cagayan Valley" },
	{ code: "PH-40", name: "Calabarzon" },
	{ code: "PH-13", name: "Caraga" },
	{ code: "PH-03", name: "Central Luzon" },
	{ code: "PH-07", name: "Central Visayas" },
	{ code: "PH-15", name: "Cordillera Administrative Region" },
	{ code: "PH-11", name: "Davao" },
	{ code: "PH-08", name: "Eastern Visayas" },
	{ code: "PH-01", name: "Ilocos" },
	{ code: "PH-41", name: "Mimaropa" },
	{ code: "PH-00", name: "National Capital Region" },
	{ code: "PH-10", name: "Northern Mindanao" },
	{ code: "PH-12", name: "Soccsksargen" },
	{ code: "PH-06", name: "Western Visayas" },
	{ code: "PH-09", name: "Zamboanga Peninsula" },
]